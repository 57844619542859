
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$rouge : #a61319;
$gris-fonce : #5d7287;
$gris-clair : #919191;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #a61319;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Small devices (landscape phones, 576px and up)
$screen-sm: 576px;

// Medium devices (tablets, 768px and up)
$screen-md: 768px;

// Large devices (desktops, 992px and up)
$screen-lg: 992px;

// Extra large devices (large desktops, 1200px and up)
$screen-xl: 1200px;

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

html{
  background-color: white;
}

#body-public {
  color: black;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  margin: 0 auto 30px auto;
  background-color: white;
  @media screen and (max-width: $screen-sm){
    width: min-content;
  }
  .container{
    max-width: 100%;
    background-color: $rouge;
    position: relative;
    @media screen and (min-width: $screen-md){
      height: 136px;
    }
    padding-top: 10px;
    #header_titre {
      @media screen and (min-width: $screen-lg){
        margin-left: 26%;
        float: left;
      }
      position: relative;
      h1 {
        font-weight: 100;
        float: left;
        font-size: 3.125em;
        line-height: 0.58em;
        color: #fefefe;
        text-transform: uppercase;
        padding: 0;
        margin: 0;
        margin-top: 32px;
        margin-left: 35px;
        small {
          font-size: 0.43em;
          color: #fefefe;
          text-transform: uppercase;
        }
      }
      img {
        width: auto;
        max-height: 122px;
        padding: 10px;
        border: 1px solid transparent;
        border-radius: 19px;
      }
      //.header_logo {
      //  float: left;
      //  height: 122px;
      //
      //}
    }
  }
  .navbar{
    background-color:white;
    max-width: 1000px;
    margin: 0 auto 0 auto;
    .navbar-toggler{
      color: $rouge;
    }
    #navbarSupportedContent > ul > li > a{
      font-size: 20px;
      color: $rouge;
    }
    #navbarSupportedContent > ul > li > a:hover{
      color: white;
      background-color: $rouge;
    }

  }

  .content {
    text-align: center;
    padding: 15px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: white;
    border-radius: 5px;
    .form-postulat {
      background-color: white;
      padding: 1.5rem;
      border-radius: 5px;
      #div-captcha img{
        border: 2px solid $rouge;
        border-radius: 5px;
      }
    }
    .titre-table{
      color: $gris-fonce;
    }
    .legende{
      float: left;
      color: $rouge;
    }
    .dataTable{
      width: 100% !important;
    }
  }
}

#body-admin {
  color: black;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  margin: 0 auto 30px auto;
  background-color: white;
  @media screen and (max-width: $screen-sm){
    width: min-content;
  }
  .container{
      max-width: 100%;
      background-color: $gris-fonce;
      position: relative;
      @media screen and (min-width: $screen-md){
        height: 136px;
      }
      padding-top: 10px;
      #header_titre {
        @media screen and (min-width: $screen-lg){
          margin-left: 23%;
          width: 54%;
          float: left;
        }
        position: relative;
        h1 {
          font-weight: 100;
          float: left;
          font-size: 3.125em;
          line-height: 0.58em;
          color: #fefefe;
          text-transform: uppercase;
          padding: 0;
          margin: 0;
          margin-top: 32px;
          margin-left: 35px;
          small {
            font-size: 0.43em;
            color: #fefefe;
            text-transform: uppercase;
          }
        }
        //.header_logo {
        //  float: left;
        //  height: 122px;
        //  img {
        //    width: auto;
        //    height: 122px;
        //    padding: 10px;
        //    border: 1px solid transparent;
        //    border-radius: 19px;
        //  }
        //}
        img {
          width: auto;
          max-height: 122px;
          padding: 10px;
          border: 1px solid transparent;
          border-radius: 19px;
        }
        //.header_logo {
        //  float: left;
        //  height: 122px;
        //
        //}
        .div-bouton-login{
          padding-top: 10px;
          color: white;
        }
        .erreurs{
          padding-top: 10px;
        }
      }

  }


  .navbar{
    background-color: white;
    max-width: 1000px;
    margin: 0 auto 0 auto;
    #navbarSupportedContent > ul > li > a{
      font-size: 18px;
      color: $gris-fonce;
      &:hover{
        color: white;
        background-color: $gris-fonce;
      }
    }
    .btn-outline-danger{
      color:$gris-fonce;
      background-color:transparent;
      background-image:none;
      border-color:$rouge;
    }
    .btn-outline-danger:hover{
      color:$rouge;
      background-color:transparent;
      background-image:none;
      border-color:$rouge;
    }
    .dropdown{
      color: $gris-fonce;
    }



  }
  .div-login-errors{
    text-align: center;
    padding: 15px;
    max-width: 1000px;
    margin: 0 auto;
  }
  .content {
    text-align: center;
    padding: 15px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: $gris-fonce;
    border-radius: 5px;
    .div-accueil-admin{
      margin: -25px;
      background-color: white;
      h1 {
        font-size: 6rem;
        color: #eeeeee;
      }
    }

    h1{
      color: white;
    }

    h2{
      color: white;
    }

    .calendar{
      background-color: white;
    }

    .form-planning{
      background-color: white;
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 1.5rem;
      .form-group{
        margin: auto;
      }
    }
    .form-personnel{
      background-color: white;
      border-radius: 5px;
      padding: 1.5rem;
    }
    .form-entite{
      background-color: white;
      padding: 1.5rem;
      border-radius: 5px;
    }

    .form-filiere{
      background-color: white;
      padding: 1.5rem;
      border-radius: 5px;
    }

    .form-domaine{
      background-color: white;
      padding: 1.5rem;
      border-radius: 5px;
    }

    .form-choix-domaine-admin{
      background-color: white;
      padding: 1.5rem;
      border-radius: 5px;
    }

    .form-recherche-personnel{
      padding: 1.5rem;
    }

    .form-postulat{
      background-color: white;
      padding: 1.5rem;
      border-radius: 5px;
      .legende-boutons{

        font-size: 0.8rem;
        font-style: italic;
        border: 2px solid $gris-fonce;
        border-radius: 5px;
        padding: 9px;
        .legende-oui {
          text-align: initial;
          span{
            color: $green;
          }
        }
        .legende-attente {
          text-align: initial;
          span{
            color: $blue;
          }
        }
        .legende-non {
          text-align: initial;
          span{
            color: $red;
          }
        }
      }
      .div-choix-a-faire{
        background-color: $rouge;
        color: white;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        padding-bottom: 3px;
        padding-top: 3px;
      }
    }

    .form-offre{
      background-color: white;
      border-radius: 5px;
      padding: 1.5rem;
      .input-group-append{
        z-index: 0;
      }
    }

    .form-login{
      background-color: white;
      border-radius: 5px;
      padding: 1.5rem;
    }
    table{
      background-color: white;

    }

    .form-mail{
      background-color: white;
      border-radius: 5px;
      padding: 1.5rem;
      ul{
        text-align: initial;
      }
    }

    .div-datatable-admin {
      background-color: white;
      padding: 1.5rem;
      border-radius: 5px;
    }

    #titre-import-bourges-plus{
      margin: 1.5rem;
    }
    #table-traitement-reponse{
      .btn-danger {
        color: #fff;
        background-color: #a61319;
        border-color: #a61319;
      }
    }
  }
  #table-liste-filieres{
    .btn-primary {
      color: #fff;
    }
  }
  #table-liste-domaines{
    .btn-primary {
      color: #fff;
    }
  }
}
.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.top-left {
  position: absolute;
  left: 10px;
  top: 18px;
}



.title {
  font-size: 84px;
}

/*.links > a {
  //color: #636b6f;
  padding: 5px 25px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}*/

.m-b-md {
  margin-bottom: 30px;
}

.bouton-accueil-offre{
  padding: 50px 30px;
}

.bouton-accueil-spontanee{
  padding: 50px 72px;
}


.div-accueil{
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  margin-top: 105px;
  margin-bottom: 150px;
}
.table {
  color: black;
  margin-top: 2rem;
}

.menu_items .menu_item>a {
  display: block;
  text-align: center;
  padding: 20px 16px;
  font-size: 0.875em;
  font-weight: 100;
  text-transform: uppercase;
  color: $rouge;
  text-decoration: none;
  height: 80px;
}
.img-logo{
  margin: 5px;
}

.form-valider-candidatures{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

a.menu-nav-link.nav-link.active{
  border-bottom: 2px solid $rouge;
}

.custom-combobox {
  width: 100%;
  position: relative;
  display: inline-block;
}
.custom-combobox-toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

#modalFilieres{

  .modal-body{
    text-align: left;
    p{
      font-size: 0.7rem;
      color: #808080;
    }
  }
}

.liste-erreurs{
  text-align: left;
}
.custom-combobox-toggle.ui-corner-right{
  i{
    font-size: 2.5rem;
    color: #495057;
  }
}

.font-size-small{
  font-size: small;
}

.logo-igm{
  width: 100%;
}


